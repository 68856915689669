<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="organizationId">
    <label for="organizationId">{{ 'Settings::GenesysIntegrationOrganizationId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="organizationId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::GenesysIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::GenesysIntegrationClientSecret' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caGroupIdFor">
    <label for="caGroupIdFor">{{ 'Settings::GenesysIntegrationCAGroupIdFor' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caGroupIdFor" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientId">
    <label for="caClientId">{{ 'Settings::GenesysIntegrationCAClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientSecret">
    <label for="caClientSecret">{{ 'Settings::GenesysIntegrationCAClientSecret' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="region">
    <label for="region">{{ 'Settings::GenesysIntegrationRegion' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="region" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="audioHookCredentialKey">
    <label for="audioHookCredentialKey">{{ 'Settings::GenesysIntegrationAudioHookCredentialKey' | abpLocalization
      }}</label>
    <input type="text" class="form-control form-control-sm" id="audioHookCredentialKey" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="audioHookCredentialSecret">
    <label for="audioHookCredentialSecret">{{ 'Settings::GenesysIntegrationAudioHookCredentialSecret' | abpLocalization
      }}</label>
    <div class="d-flex">
      <input type="text" class="form-control form-control-sm" id="audioHookCredentialSecret" formControlName="value" />

      <button type="button" (click)="generateRandomBase64String()" class="btn btn-primary generate-btn me-1 ms-2">
        Generate
      </button>
    </div>

  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>