import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'cr-amazon-integration-settings',
  templateUrl: './amazon-integration-settings.component.html',
  styleUrls: ['./amazon-integration-settings.component.scss'],
})
export class AmazonIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'AmazonIntegration';
  private readonly region = `${this.prefix}.Region`;
  private readonly connectInstanceArn = `${this.prefix}.ConnectInstanceArn`;
  private readonly awsSecretKey = `${this.prefix}.AwsSecretKey`;
  private readonly awsAccessKey = `${this.prefix}.AwsAccessKey`;
  private readonly caClientId = `${this.prefix}.CAClientId`;
  private readonly caClientSecret = `${this.prefix}.CAClientSecret`;
  private readonly caGroupIdFor = `${this.prefix}.CAGroupIdFor`;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      awsSecretKey: this.fb.group({
        name: this.awsSecretKey,
        value: [
          this.config.getSetting(this.awsSecretKey),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      awsAccessKey: this.fb.group({
        name: this.awsAccessKey,
        value: [
          this.config.getSetting(this.awsAccessKey),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      connectInstanceArn: this.fb.group({
        name: this.connectInstanceArn,
        value: [
          this.config.getSetting(this.connectInstanceArn),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientId: this.fb.group({
        name: this.caClientId,
        value: [
          this.config.getSetting(this.caClientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caClientSecret: this.fb.group({
        name: this.caClientSecret,
        value: [
          this.config.getSetting(this.caClientSecret),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      region: this.fb.group({
        name: this.region,
        value: [
          this.config.getSetting(this.region),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      caGroupIdFor: this.fb.group({
        name: this.caGroupIdFor,
        value: [
          this.config.getSetting(this.caGroupIdFor),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      })
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('awsSecretKey').get('name').value,
      settingValue: this.form.get('awsSecretKey').get('value').value,
    });

    settings.push({
      settingName: this.form.get('awsAccessKey').get('name').value,
      settingValue: this.form.get('awsAccessKey').get('value').value,
    });
    
    settings.push({
      settingName: this.form.get('caClientId').get('name').value,
      settingValue: this.form.get('caClientId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caClientSecret').get('name').value,
      settingValue: this.form.get('caClientSecret').get('value').value,
    });
    
    settings.push({
      settingName: this.form.get('region').get('name').value,
      settingValue: this.form.get('region').get('value').value,
    });

    settings.push({
      settingName: this.form.get('caGroupIdFor').get('name').value,
      settingValue: this.form.get('caGroupIdFor').get('value').value,
    });

    settings.push({
      settingName: this.form.get('connectInstanceArn').get('name').value,
      settingValue: this.form.get('connectInstanceArn').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
