import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AudioFormatDto } from 'src/core/models/audio-format/audio-format-dto';
import { VoiceFileInformationDto } from 'src/core/models/voice-file-information/voice-file-information.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { StorageType } from 'src/cr-shared/voice-file-information/storage-type-enum';

@Component({
  selector: 'voice-file-information-edit-modal',
  templateUrl: './voice-file-information-edit-modal.component.html',
  styleUrl: './voice-file-information-edit-modal.component.scss',
})
export class VoiceFileInformationEditModalComponent {
  @Output()
  onUpdateSaved: EventEmitter<{}> = new EventEmitter();

  form = this.fb.group({
    storageTypeId: [{ value: 0, disabled: true }, [Validators.required]],
    // relativePath: ['', [Validators.required]],
    audioFormatId: [0, [Validators.required]],
    fileExtension: ['', [Validators.required]],
    awsAccessKeyId: ['', [Validators.required]],
    awsSecretAccessKey: ['', [Validators.required]],
    awsRegion: ['', [Validators.required]],
    awsBucketName: ['', [Validators.required]],
    awsServiceUrl: ['', [Validators.required]],
    shouldWebProxySetNull: [false, [Validators.required]],
  });

  selectedVfi: VoiceFileInformationDto = null;
  isSelectedVfiAws: boolean = false;
  inProgress: boolean;
  isModalOpen: boolean;
  audioFormats: AudioFormatDto[] = [];
  storageTypes: any[] = [];

  constructor(private fb: FormBuilder, private crudService: CrudService, private L: LocalizationService) {
    this.audioFormats = JSON.parse(localStorage.audioFormats)
    this.storageTypes = JSON.parse(localStorage.storageTypes);
  }

  prepareStorageTypes() {
    const storagePropArray = [
      {
        Id: this.selectedVfi["awsAccessKeyIdId"],
        Key: "awsAccessKeyId",
        Value: this.form.controls["awsAccessKeyId"].value,
        VoiceFileInformationId: this.selectedVfi.id
      },
      {
        Id: this.selectedVfi["awsSecretAccessKeyId"],
        Key: "awsSecretAccessKey",
        Value: this.form.controls["awsSecretAccessKey"].value,
        VoiceFileInformationId: this.selectedVfi.id
      },
      {
        Id: this.selectedVfi["awsRegionId"],
        Key: "awsRegion",
        Value: this.form.controls["awsRegion"].value,
        VoiceFileInformationId: this.selectedVfi.id
      },
      {
        Id: this.selectedVfi["awsBucketNameId"],
        Key: "awsBucketName",
        Value: this.form.controls["awsBucketName"].value,
        VoiceFileInformationId: this.selectedVfi.id
      },
      {
        Id: this.selectedVfi["awsServiceUrlId"],
        Key: "awsServiceUrl",
        Value: this.form.controls["awsServiceUrl"].value,
        VoiceFileInformationId: this.selectedVfi.id
      },
      {
        Id: this.selectedVfi["shouldWebProxySetNullId"],
        Key: "shouldWebProxySetNull",
        Value: this.form.controls["shouldWebProxySetNull"].value.toString(),
        VoiceFileInformationId: this.selectedVfi.id
      },
    ];

    this.selectedVfi.storageProperties = storagePropArray;
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;

    this.selectedVfi.audioFormatId = this.form.controls["audioFormatId"].value;
    this.selectedVfi.storageTypeId = this.form.controls["storageTypeId"].value;
    this.selectedVfi.relativePath = "{Year}\\{Month}\\{Day}\\{Hour}\\{Extid}";
    this.selectedVfi.fileExtension = this.form.controls["fileExtension"].value;

    if (this.selectedVfi.storageTypeId == StorageType.AWS) {
      this.prepareStorageTypes();
    } else {
      this.selectedVfi.storageProperties = null;
    }

    this.crudService
      .update<VoiceFileInformationDto>(VoiceFileInformationDto, this.selectedVfi)
      .subscribe((result) => {
        if (result) {
          this.onUpdateSaved.emit();
        }
        this.isModalOpen = false;
        this.inProgress = false;
        this.form.reset();
      });
  }

  load(data: VoiceFileInformationDto | null) {
    if (data == null) {
      data = new VoiceFileInformationDto();
    } else {
      this.deleteAwsFields();
      this.selectedVfi = data;

      if (this.selectedVfi.storageTypeId == StorageType.AWS) {
        this.isSelectedVfiAws = true;
        this.addAwsValidators();
      } else {
        this.isSelectedVfiAws = false;
        this.clearAwsValidators();
      }
      this.form.patchValue(data);
    }
  }

  deleteAwsFields() {
    this.form.controls['awsAccessKeyId'].setValue('');
    this.form.controls['awsSecretAccessKey'].setValue('');
    this.form.controls['awsRegion'].setValue('');
    this.form.controls['awsBucketName'].setValue('');
    this.form.controls['awsServiceUrl'].setValue('');
    this.form.controls['shouldWebProxySetNull'].setValue(false);
  }

  addAwsValidators(){
    this.form.controls['awsAccessKeyId'].addValidators(Validators.required);
    this.form.controls['awsAccessKeyId'].updateValueAndValidity()
    this.form.controls['awsSecretAccessKey'].addValidators(Validators.required);
    this.form.controls['awsSecretAccessKey'].updateValueAndValidity();
    this.form.controls['awsRegion'].addValidators(Validators.required);
    this.form.controls['awsRegion'].updateValueAndValidity();
    this.form.controls['awsBucketName'].addValidators(Validators.required);
    this.form.controls['awsBucketName'].updateValueAndValidity();
    this.form.controls['awsServiceUrl'].addValidators(Validators.required);
    this.form.controls['awsServiceUrl'].updateValueAndValidity();
  }

  clearAwsValidators() {
    this.form.controls['awsAccessKeyId'].clearValidators();
    this.form.controls['awsAccessKeyId'].updateValueAndValidity()
    this.form.controls['awsSecretAccessKey'].clearValidators();
    this.form.controls['awsSecretAccessKey'].updateValueAndValidity();
    this.form.controls['awsRegion'].clearValidators();
    this.form.controls['awsRegion'].updateValueAndValidity();
    this.form.controls['awsBucketName'].clearValidators();
    this.form.controls['awsBucketName'].updateValueAndValidity();
    this.form.controls['awsServiceUrl'].clearValidators();
    this.form.controls['awsServiceUrl'].updateValueAndValidity();
  }
}