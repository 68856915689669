<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="connectInstanceArn">
    <label for="connectInstanceArn">{{ 'Settings::AmazonIntegrationConnectInstanceArn' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="connectInstanceArn"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="awsAccessKey">
    <label for="awsAccessKey">{{ 'Settings::AmazonIntegrationAwsAccessKey' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="awsAccessKey"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="awsSecretKey">
    <label for="awsSecretKey">{{ 'Settings::AmazonIntegrationAwsSecretKey' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="awsSecretKey"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="region">
    <label for="region">{{ 'Settings::AmazonIntegrationRegion' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="region"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientId">
    <label for="caClientId">{{ 'Settings::AmazonIntegrationCAClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caClientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientSecret">
    <label for="caClientSecret">{{ 'Settings::AmazonIntegrationCAClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caClientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caGroupIdFor">
    <label for="caGroupIdFor">{{ 'Settings::AmazonIntegrationCAGroupIdFor' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="caGroupIdFor"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
