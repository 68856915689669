<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="accountSid">
    <label for="accountSid">{{ 'Settings::TwilioIntegrationAccountSid' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="accountSid" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="workspaceSid">
    <label for="workspaceSid">{{ 'Settings::TwilioIntegrationWorkspaceSid' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="workspaceSid" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="authToken">
    <label for="authToken">{{ 'Settings::TwilioIntegrationAuthToken' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="authToken" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientId">
    <label for="caClientId">{{ 'Settings::TwilioIntegrationCAClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caClientSecret">
    <label for="caClientSecret">{{ 'Settings::TwilioIntegrationCAClientSecret' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caClientSecret" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="caQueueIdFor">
    <label for="caQueueIdFor">{{ 'Settings::TwilioIntegrationCAQueueIdFor' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="caQueueIdFor" formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>