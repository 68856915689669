import { Component, OnInit } from '@angular/core';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';

@Component({
  selector: 'cr-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss'],
})
export class IntegrationSettingsComponent implements OnInit {
  activeTab = 1;
  isGenesysIntegrationActive: boolean = true;
  isAmazonIntegrationActive: boolean = true;
  isFive9IntegrationActive: boolean = true;
  isTwilioIntegrationActive: boolean = true;

  constructor(
    private featureService: FeatureService,
  ) {
    if (
      !this.featureService.isEnabled(FeatureConstants.GenesysIntegration) &&
      !this.featureService.isEnabled(FeatureConstants.GenesysIntegrationOutOfMarketplace)
    ) {
      this.isGenesysIntegrationActive = false;
    }

    if (
      !this.featureService.isEnabled(FeatureConstants.AmazonIntegration) &&
      !this.featureService.isEnabled(FeatureConstants.AmazonIntegrationOutOfMarketplace)) {
      this.isAmazonIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.Five9Integration)) {
      this.isFive9IntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.TwilioIntegration)) {
      this.isTwilioIntegrationActive = false;
    }

    if (this.isGenesysIntegrationActive) {
      this.activeTab = 1;
    } else if (this.isAmazonIntegrationActive) {
      this.activeTab = 2;
    } else if (this.isFive9IntegrationActive) {
      this.activeTab = 3;
    } else if (this.isTwilioIntegrationActive) {
      this.activeTab = 4;
    }
  }

  ngOnInit(): void {}
}
